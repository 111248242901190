<template>
    <div class="mt-2 p-0 mr-0 ml-0 mb-0">
        <v-row>
            <v-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
               &nbsp;
            </v-col>
            <v-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
               <div>
                    <v-text-field
                        v-model="userparams.name"
                        label="Nome"
                        outlined
                        :class="$css_field"
                        readonly
                        filled
                    >   
                    </v-text-field>
               </div>
               <div>
                    <v-select
                        v-model="userparams.tenant_id"
                        :items="listitems.data"
                        label="Cliente"
                        outlined
                        item-value="id"
                        item-text="nome"
                        :class="$css_field"
                    ></v-select>
               </div>
               <div>
                    <v-btn
                        block
                        dark
                        color="primary"
                        @click="changeUserClient"
                        :class="$css_button"
                    >
                        Alterar
                    </v-btn>
               </div>
            </v-col>
            <v-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
               &nbsp;
            </v-col>
        </v-row>
    </div>
</template>

<script>

export default {
    created() {
        this.loadItems()
    },

    computed: {
        listitems() {
            return this.$store.state.tenants.items    
        },

        me () {
          return this.$store.state.auth.me
        }
    },    

    data () {
        return {
            item: {},
            params: {
                paginate: 'false'
            },

            userparams: {
                name: 'Roberto Noya',
                user_id: '93d9059d-4373-4aa4-a58c-5aaa3e9068cd',
                tenant_id: ''
            }
        }
    },


    methods: {
       
        loadItems () {
            this.$store.dispatch('loadTenants', this.params) 
        },

        changeUserClient(){
            this.$store.dispatch('changeUserClient', this.userparams)
                        .then(response => {
                            this.$snotify.success('Cadastro com sucesso!')
                        })
                        .catch(error => {
                            this.$snotify.error('Erro no cadastro')
                        })
        }
    }

}
</script>

<style scoped>
.subtitle {
  min-height: 150px;
}
</style>