<template>
    <div>
        <v-row class="mt-4">
            <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                <v-card
                    class="mx-auto"
                    outlined
                >
                
                <v-data-table
                    :headers="headers"
                    :items="object.data"
                    :options.sync="options"
                    :server-items-length="object.meta && object.meta.total"
                    class="elevation-1 table-striped caption"
                    :footer-props="{
                        'items-per-page-text':'Itens por página',
                        'items-per-page-options': [5, 10],
                        'items-per-page-all-text': '',
                        'page-text': '{0}-{1} de {2}'
                    }"
                >
                    
                    <template v-slot:[`item.nome`]="{ item }">
                        <span class="font-weight-bold">{{ item.nome }}</span>
                        <div>
                            <span :class="$css_report_list">{{ item.url }}</span>
                        </div>
                    </template>

                    <template v-slot:[`item.status`]="{ item }">
                        <v-chip
                            :color="item.status | statusTenant"
                            label
                            small
                        >
                            {{ item.status }}
                        </v-chip> 
                    </template>

                    <template v-slot:[`item.dados_cobranca`]="{ item }">
                        <div>
                            <span :class="$css_report_list">{{ item.invoice_name }}</span> 
                            <span :class="$css_report_list"> ({{ item.invoice_doc }})</span>
                        </div>
                        <div v-if="item.endereco">
                            <span :class="$css_report_list">Dia venc.: {{ item.due_day }}</span>
                            <span :class="$css_report_list + ' ml-4'">
                                Endereço: {{ item.endereco }}
                                <span v-if="item.numero">, {{ item.numero }}</span>
                                <span v-if="item.complemento"> - {{ item.complemento }}</span>
                            </span> 
                        </div>
                    </template>

                    <template v-slot:[`item.dados_contato`]="{ item }">
                        <div>
                            <v-menu
                                open-on-click
                                top
                                offset-y
                            >
                                <template v-slot:activator="{ on, attrs }">
                                    <v-icon  
                                        v-bind="attrs"
                                        v-on="on"

                                    >
                                        mdi-card-account-mail-outline
                                    </v-icon>
                                </template>

                                <v-simple-table>
                                    <template v-slot:default>
                                    <tbody>
                                        <tr v-if="item.telefone1">
                                            <td>Telefone 1: </td>
                                            <td><phone-component :object="item.telefone1"></phone-component></td>
                                        </tr>
                                        <tr v-if="item.telefone2">
                                            <td>Telefone 2: </td>
                                            <td><phone-component :object="item.telefone2"></phone-component></td>
                                        </tr>
                                        <tr v-if="item.email1">
                                            <td>E-mail 1: </td>
                                            <td>{{ item.email1 }}</td>
                                        </tr>
                                        <tr v-if="item.email2">
                                            <td>E-mail 2: </td>
                                            <td>{{ item.email2 }}</td>
                                        </tr>
                                        <tr>
                                            <td colspan="2" class="text-center font-weight-bold">{{ item.nome }}</td>
                                        </tr>
                                    </tbody>
                                    </template>
                                </v-simple-table>
                            </v-menu>
                        </div>
                    </template>

                    <template v-slot:[`item.plano`]="{ item }">
                        <div>
                            <v-menu
                                open-on-click
                                top
                                offset-y
                            >
                                <template v-slot:activator="{ on, attrs }">
                                    <v-icon  
                                        v-bind="attrs"
                                        v-on="on"

                                    >
                                        mdi-account-cash-outline
                                    </v-icon>
                                </template>
     
                                <v-simple-table>
                                    <template v-slot:default>
                                    <thead>
                                        <tr>
                                            <th class="text-left">
                                                DESCRIÇÃO
                                            </th>
                                            <th class="text-left">
                                                R$ / LIMITE
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="itm in item.json_account_values" :key="itm.id">
                                            <td>{{ itm.description }}</td>
                                            <td>R$ {{ itm.value }}</td>
                                        </tr>
                                        <tr>
                                            <td>Usuários</td>
                                            <td>{{ item.app_plan.users }}</td>
                                        </tr>
                                        <tr>
                                            <td>Imóveis</td>
                                            <td>{{ item.app_plan.properties }}</td>
                                        </tr>
                                        <tr>
                                            <td>Condomínios</td>
                                            <td>{{ item.app_plan.condominiums }}</td>
                                        </tr>
                                        <tr>
                                            <td>FAC</td>
                                            <td>{{ item.app_plan.customers }}</td>
                                        </tr>
                                        <tr v-if="item.payment_method">
                                            <td>Forma de PGTO </td>
                                            <td>{{ item.payment_method }}</td>
                                        </tr>
                                        <tr>
                                            <td colspan="2" class="text-center font-weight-bold">{{ item.nome }}</td>
                                        </tr>
                                    </tbody>
                                    </template>
                                </v-simple-table>
                            </v-menu>
                        </div>
                    </template>

                    <template v-slot:[`item.actions`]="{ item }">
                        <v-card-actions>
                            <v-tooltip top>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn icon @click.prevent="editItem(item.id)" class="ml-8">
                                        <v-icon v-bind="attrs" v-on="on">mdi-pencil</v-icon>
                                    </v-btn>
                                </template>
                                <span>Editar</span>
                            </v-tooltip>
                            <v-tooltip top>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn icon @click.prevent="confirmDestroy(item)" class="ml-2">
                                        <v-icon v-bind="attrs" v-on="on">mdi-delete</v-icon>
                                    </v-btn>
                                </template>
                                <span>Excluir</span>
                            </v-tooltip>
                        </v-card-actions>     
                    </template> 
                </v-data-table>
               
                </v-card>
            </v-col>
        </v-row>

    </div>
</template>

<script>
import PhoneComponent from '@/components/phone/PhoneComponent.vue'
//import FormLabelComponent from './FormLabelComponent.vue'; 

export default {

    components: {
        PhoneComponent
        //FormLabelComponent
    },

    props: {
        object: {},

        filters: {},
    }, 

    watch: {
        options: {
            handler() {
                if(this.options.page >= 1){
                    this.loadItems();
                }   
            },
        },

        filters () {
            this.options.page = 1
        },

    },

    data () {
        return {
            options: {
                page: 1,
                itemsPerPage: 10,
            },

            headers: [
                { text: 'STATUS', value: 'status' , sortable: false},  
                { text: 'NOME', value: 'nome' , sortable: false}, 
                { text: 'DADOS COBRANÇA', value: 'dados_cobranca' , sortable: false},  
                { text: 'CONTATO', value: 'dados_contato' , sortable: false},  
                { text: 'PLANO', value: 'plano' , sortable: false},  
                { text: 'AÇÕES', value: 'actions', sortable: false },
            ],

            confirmDelete: {
                id: '',
                status: false,
                name: '',
                text: '',
                destroyfunction: '',
            },
        }    
    },

    methods: {
        loadItems(){
            this.params = this.filters 
            this.params.page = this.options.page
            if(this.options.itemsPerPage && this.options.itemsPerPage > 0){
                this.params.paginate = this.options.itemsPerPage
            }
            this.$emit('callLoadItems', this.params)   
        },

        openCloseDialog() {
            this.dialog = !this.dialog
        },

        editItem(id){
            this.$emit('callEditItem', id)
        },

        confirmDestroy(item){
            this.$snotify.error(`Tem certeza que deseja excluir esse registro?`, 'Confirmaçāo', {
                timeout: 10000,
                showProgressBar: true,
                closeоnClick: true,
                buttons: [
                    {text: 'Nāo', action: this.$snotify.remove()},
                    {text: 'Sim', action: () => {
                        this.destroy(item)
                        this.$snotify.remove()
                    }},
                ]
            })
        },

        destroy(item){
            this.$store.dispatch('destroyTenant', item.id)
                        .then(() => {
                            this.$snotify.success(`Registro excluído com sucesso`, 'Delete')
                            this.$emit('callLoadItems')
                        })
                        .catch(error => {
                            this.$snotify.error('error', error)
                        })
        },
        
        setTotal(){

        }
    }
}
</script>
