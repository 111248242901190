var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-row',{attrs:{"dense":""}},[_vm._l((_vm.dashboard.tenants),function(itm){return _c('v-col',{key:itm.description,attrs:{"cols":"12","xs":"12","sm":"12","md":"2","lg":"2","xl":"2"}},[_c('v-card',{staticClass:"mx-auto pa-3",attrs:{"outlined":"","min-height":"60px"}},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',[_c('v-icon',{attrs:{"x-large":"","color":_vm._f("statusTenant")(itm.description)}},[_vm._v("mdi-account-group-outline")])],1),_c('v-col',{staticClass:"text-left"},[_c('router-link',{attrs:{"to":{ 
                                name: 'admin.tenants.index', 
                                params: {
                                    status: itm.description                       
                                } 
                        }}},[_c('div',[_c('div',{staticClass:"text-subtitle-2",staticStyle:{"color":"black"}},[_vm._v(_vm._s(itm.description))]),_c('div',{staticClass:"text-body-2",staticStyle:{"color":"black"}},[_vm._v(_vm._s(itm.total))])])])],1)],1)],1)],1)}),_vm._l((_vm.dashboard.accounts),function(itm){return _c('v-col',{key:itm.description,attrs:{"cols":"12","xs":"12","sm":"12","md":"2","lg":"2","xl":"2"}},[_c('v-card',{staticClass:"mx-auto pa-3",attrs:{"outlined":"","min-height":"60px"}},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',[_c('v-icon',{attrs:{"x-large":"","color":_vm._f("statusMens")(itm.description)}},[_vm._v("mdi-account-cash-outline")])],1),_c('v-col',{staticClass:"text-left"},[_c('router-link',{attrs:{"to":{ 
                                name: 'admin.accounts.index', 
                                params: {
                                    status_payment: itm.status                       
                                } 
                        }}},[_c('div',[_c('div',{staticClass:"text-subtitle-2",staticStyle:{"color":"black"}},[_vm._v(_vm._s(itm.description))]),_c('div',{staticClass:"text-body-2",staticStyle:{"color":"black"}},[_vm._v(_vm._s(itm.total))])])])],1)],1)],1)],1)})],2),_c('v-row',[_c('v-col',{class:_vm.$css_show_description,attrs:{"cols":"12","xs":"12","sm":"12","md":"12","lg":"12","xl":"12"}},[_c('calendar-component')],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }